$(document).ready(function() {
    const chat_history = document.querySelector(".chat-history");
    if(chat_history){
        chat_history.scrollTop = chat_history.scrollHeight;
        window.setTimeout( function() {
            window.location.reload();
        }, 30000);
    }

    $('.wa_share_offer_btn').click(function(event){
        event.preventDefault();
        const id = $(this).data('message-id');
        const form = $('#wa_share_forward_form_' + id);
        form.find('#wa_number').removeClass('d-none');
        form.find('#wa_number').focus();
        form.find(':submit').removeClass('d-none');
        $(this).hide();
    })

    $('.wa_resend_offer_btn').click(function(event){
        event.preventDefault();
        if(confirm('Are you sure you want to resend the offer?')){
            console.log('confirmed');
            $('#wa_share_forward_form_' + $(this).data('message-id')).submit();
        }
    })

    $('#wa-chat-form-input').on('keypress', function(e) {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault(); // Prevent new line
            $('#wa-chat-form').submit();
        }
    });


});